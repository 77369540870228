@primaryColor: #f44336;
@themeBackground: #eee;
@activeColor: #9c27b0;
@lightBackground: #fafafa;
@darkGrey: #243238;
@amber: #f37435;
@indigo: rgb(102,16,242);
@cobalt: #0094ff;

.bg-dark-grey {
    background-color: @darkGrey;
}

.theme-primary {
    background-color: @primaryColor !important;
}

.theme-background {
    background-color: @themeBackground !important;
}

.theme-secondary {
    background-color: @activeColor !important;
}

.theme-light-background {
    background-color: @lightBackground !important;
}

.list-group li {
    cursor: default;

    &.active-light {
        background-color: #aadaff !important;
    }
}

.map-panel {
    .mat-expansion-panel-content, .mat-expansion-panel-body {
        height: 100%;
        padding: 0;
    }

    &.with-scroll {
        .mat-expansion-panel-body {
            overflow-y: auto;
        }
    }
}

a.no-decoration {
    text-decoration: none !important;
    
    &:hover {
        text-decoration: none !important;
    }
}
