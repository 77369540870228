/* You can add global styles to this file, and also import other style files */
@import "assets/less/app-loader";
@import "assets/less/colors";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/css/material-dashboard.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
.fillParent() {
  & {
    height: 100%;
    width: 100%;
  }
}

html {
  .fillParent();

  body {
    background-color: #eee;
    color: #3c4858;
    .fillParent();
  }
}

.form-group .feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}

.has-vn-error {
  input {
    border-color: #dc3545 !important;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.logo {
  a {
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.sidebar .notification {
  position: absolute;
  top: 5px;
  border: 1px solid #fff;
  left: 42px;
  font-size: 9px;
  background: #f44336;
  color: #ffffff;
  min-width: 20px;
  padding: 0px 5px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 19px !important;
  vertical-align: middle;
  display: block;
}

.btn-label {
  position: relative;
  height: 100%;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.average-tooltip {
  font-size: 0.8rem !important;
}

.btn-label-no-bg {
  position: relative;
  height: 100%;
  display: inline-block;
  padding: 6px 12px;
}

.btn-labeled {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.btn {
  margin-bottom: 10px;
}

input.theme-background {
  border: 0;
  transition: background 0.15s;
  border-bottom-left-radius: 0;
  margin-right: 2px;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    background-size: 100% 100%, 100% 100%;
    transition-duration: 0.3s;
  }
}

input,
select {
  &.flat-control {
    border: 0;
    transition: background 0.15s;
    border-bottom-left-radius: 0;
    margin-right: 2px;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
      background-size: 100% 100%, 100% 100%;
      transition-duration: 0.3s;
    }
  }
}

select {
  &.flat-control {
    border: 0;
  }
}

.breadcrumb {
  background-color: @themeBackground !important;
  padding: 0.75rem 0 !important;
  margin-bottom: 0 !important;
}

.intl-tel-input {
  width: 100%;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: @activeColor;
}

.mat-select-raised {
  z-index: 2000 !important;
}

.list-group-item {
  .img-container {
    width: 15%;
    min-width: 5rem;
    max-width: 15%;
  }
}

.img-container-small {
  min-width: 3rem;
  max-width: 4rem;
}

.card {
  .img-container-small {
    img {
      border-radius: 0 !important;
    }
  }
}

@media (max-width: 576px) {
  .main-content {
    padding: 30px 0 !important;
  }

  .list-group-item {
    .img-container {
      max-width: 100%;
    }

    .list-body {
      padding-left: 0 !important;
      margin-left: 0 !important;
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  }

  .input-group {
    .aside-input {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 256px) {
  .list-group-item {
    .img-container-small {
      max-width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .input-group {
    .aside-input {
      margin-left: 0 !important;
    }
  }
}

.material-icons {
  &.md-18 {
    font-size: 18px;
  }

  &.md-dark {
    color: rgba(0, 0, 0, 0.54);

    &.md-inactive {
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.md-light {
    color: rgba(255, 255, 255, 1);

    &.md-inactive {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.modal-window {
  border-radius: 0.25rem !important;

  img {
    border-radius: 0.25rem !important;
  }
}

.modal-window-select {
  .modal-window();
}

.modal-backdrop {
  opacity: 0.9 !important;
  background-color: rgba(0.5, 0.5, 1, 1) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cus-form-group {
  .feedback {
    margin-top: -16px;
    font-size: 0.85rem;
    margin-bottom: 16px;
  }
}

.btn-mat-theme {
  background-color: #3f51b5 !important;
}

// Google maps control container
.pac-container {
  z-index: 4000 !important;
}

app-list-view,
app-places-search-form-control {
  &.ng-invalid {
    &.ng-touched > .card {
      border: 1px solid #f44336;
    }
  }
}

li.active {
  &.active-light {
    background-color: rgba(0, 123, 255, 0.7);
  }
}

.add-phone-dialog {
  .mat-dialog-container {
    overflow: visible !important;
  }
}

.more-options-menu:focus {
  outline: none;
}

// Virtualized select list item sizes
app-product-list-dialog {
  display: block;
  height: 100%;
}

.product-list-item {
  height: 50px;
}

.driver-list-item {
  height: 64px;
}

// Force dialog overlays forward
.cdk-overlay-container {
  z-index: 2000 !important;
}

.ng-invalid > .control-anchor {
  border-color: crimson;
}

.ng-invalid.ng-touched > .control-anchor-touched {
  border-color: crimson;
}

.payment-item-status-spinner {
  circle {
    stroke: #fff;
  }
}

body {
  margin-top: -16px !important;
}

.main-content {
  padding: 4px !important;
}

.form-dialog {
  max-width: 50vw !important;
  width: 40vw !important;
  max-height: 99vh !important;
}

.medium-dialog {
  max-width: 75vw !important;
  width: 50vw !important;
  max-height: 90vh !important;
}

.big-dialog {
  max-width: 100vw !important;
  width: 80vw !important;
  max-height: 90vh !important;
}

google-map{
  .map-container{
    width: 100% !important;
  }
}