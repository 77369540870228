.app-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 75px;
    height: 75px;

    .spinner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 50%;
        height: 50%;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            content: "";
            border-radius: 100%;
            width: 50%;
            height: 50%;
            transform: scale(0);
        }

        @spinnerCount: 8;
        @spinnerColors: #00ff80 #00ffea #00aaff #0040ff #2a00ff #9500ff #ff00ff #ff0095;

        .spinnerGenerator(@colors, @count, @i: 1) when (@i <= @count) {
            @currentColor: extract(@colors, @i);
            @currentDelay: unit(0.1 * @i, s);

            &:nth-child(@{i}) {
                transform: rotate(unit(45 * @i, deg));

                &::before {
                    animation: spin 0.8s linear @currentDelay infinite normal;
                    background: @currentColor none repeat scroll 0 0;
                }
            }

            .spinnerGenerator(@colors, @count, @i + 1);
        }

        .spinnerGenerator(@spinnerColors, @spinnerCount);
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .app-loader {
        height: 100px;
        width: 100px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .app-loader {
        height: 125px;
        width: 125px;
    }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .app-loader {
        height: 150px;
        width: 150px;
    }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .app-loader {
        height: 175px;
        width: 175px;
    }
}

@keyframes spin {
    100% {
        opacity: 0.1;
        transform: scale(1);
    }
}

.status-enable {
    position: relative;

    &:after {
        position: absolute;
        content: '';
        background-color: crimson;
        top: 1.25rem;
        left: 0.32rem;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 5px;
    }
}
